<template>
  <div>
    <!-- table-box -->
    <div>
      <div class="rtl-right search-box">
        <el-row :gutter="5">
          <el-col :span="3">
            <el-select v-model="filterForm.course_category_id" class="rtl-right" @change="toSearch()" clearable placeholder="ئاساسىي تىزىملىك">
              <el-option
                v-for="item in bigCategoryData"
                :key="item.id"
                :label="item.name_ug"
                :value="item.id">
              </el-option>
            </el-select>
          </el-col>
          <el-col :span="5">
            <el-select v-model="filterForm.teacher_id" class="rtl-right" @change="toSearch()" clearable placeholder="ئوقۇتقۇچى">
              <el-option
                v-for="item in teachersData"
                :key="item.id"
                :label="item.name_ug"
                :value="item.id">
              </el-option>
            </el-select>
          </el-col>
          <el-col :span="5">
            <el-input v-model="filterForm.keyword" @keyup.native.enter="toSearch()" clearable placeholder="ھالقىلىق سۆزنى كىرگۈزۈڭ"></el-input>
          </el-col>
          <el-col :span="7">
          <el-date-picker
            style="width:100%"
            class="ltr-left"
            v-model="filterForm.timeRange"
            type="datetimerange"
            value-format="yyyy-MM-dd HH:mm:ss"
            range-separator="-"
            unlink-panels
            @change="dateTimeChage()"
            start-placeholder="باشلىنىش ۋاقتى"
            end-placeholder="ئاخىرلىشىش ۋاقتى">
          </el-date-picker>
        </el-col>
          <el-col :span="4">
            <el-button type="success" plain icon="el-icon-search" @click="toSearch()">ئىزدەش </el-button>
            <el-button type="danger" plain icon="el-icon-circle-close" @click="toClear()">بىكار  قىلىش </el-button>
          </el-col>
        </el-row>
      </div>

      <div class="table-box">
        <el-table
          :data="tableData" 
          border 
          style="width: 100%"
          @sort-change="sortChange" 
          v-loading="loading">
          <el-table-column type="index" :index="indexMethod" label="#"></el-table-column>
          <el-table-column prop="name_ug" label="دەرس ئىسمى"></el-table-column>
          <el-table-column prop="category.name_ug" label="تۈرى"></el-table-column>
          <el-table-column prop="teacher.name_ug" label="ئوقۇتقۇچى"></el-table-column>
          <el-table-column prop="price" label="باھاسى"></el-table-column>
          <el-table-column prop="total_count" label="سىتىلغان سانى"></el-table-column>
          <!-- <el-table-column prop="total_price" sortable="custom" label="سىتىلغان سومما"></el-table-column> -->
          <el-table-column prop="total_price" label="سىتىلغان سومما"></el-table-column>
          <el-table-column prop="teacher_profit" label="ئوقۇتقۇچى پايدىسى"></el-table-column>
          <el-table-column prop="agents_profit" label="ۋاكالەتچى پايدىسى"></el-table-column>
          <el-table-column prop="vips_profit" label="VIPئەزا پايدىسى"></el-table-column>
          <el-table-column prop="users_profit" label="ئادەتتىكى ئەزا پايدىسى"></el-table-column>
          <el-table-column prop="platform_profit" label="دەرسخانا پايدىسى"></el-table-column>
        </el-table>
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          background
          :current-page="current_page"
          :page-sizes="[10, 20, 30, 40, 50, 100]"
          layout="sizes,prev, pager, next,total,jumper"
          :total="total">
        </el-pagination> 
      </div>

      <el-divider></el-divider>

      <el-row style="padding: 40px 0;">
        <el-col :span="14">
          <div id="chartbox" style="width: 80%;height:500px;margin:auto;"></div>
        </el-col>
        <el-col :span="10">
          <div class="rtl-right total-box">
            <p style="color: #409EFF;">
              <label>سىتىلغان ئومومىي سانى</label>
              <span>{{totalData.total_count}}</span>
            </p>
            <p style="color: #67C23A;">
              <label>سىتىلغان ئومومىي سومما</label>
              <span>{{totalData.total_price}}</span>
            </p>
            <p style="color: #E6A23C;">
              <label>ئوقۇتقۇچى پايدىسى</label>
              <span>{{totalData.teacher_profit}}</span>
            </p>
            <p style="color: #F56C6C;">
              <label>ۋاكالەتچى ئومومىي پايدىسى</label>
              <span>{{totalData.agents_profit}}</span>
            </p>
            <p style="color: #61A0A9;">
              <label>VIPئەزا ئومومىي پايدىسى</label>
              <span>{{totalData.vips_profit}}</span>
            </p>
            <p style="color: #2F4553;">
              <label>ئادەتتىكى ئەزا ئومومىي پايدىسى</label>
              <span>{{totalData.users_profit}}</span>
            </p>
            <p style="color: #FA7922;">
              <label>دەرسخانا ئومومىي پايدىسى</label>
              <span>{{totalData.platform_profit}}</span>
            </p>
            <!-- <p style="color: #409EFF;">
              <label>ۋاكالەتچىلىك سېتلغان سانى</label>
              <span>1</span>
            </p>
            <p style="color: #67C23A;">
              <label>ۋاكالەتچىلىك سېتلغان سومما</label>
              <span>1</span>
            </p>
            <p style="color: #409EFF;">
              <label>vip سېتلغان سانى</label>
              <span>1</span>
            </p>
            <p style="color: #67C23A;">
              <label>vip سېتلغان سومما</label>
              <span>1</span>
            </p> -->
          </div>
        </el-col>
      </el-row>
      

    </div>
  </div>
</template>

<script>
  var self;
  var echarts = require('echarts');
  
  export default {
    activated: function() {
      self = this;
      document.querySelector("a[href='/statistics']").className = 'active-menu';
      self.getList();
      self.getBigCategory();
      self.getTeachersList();
      self.getTotalData();
      self.getChartData();
    },
    data() {
      return {
        tableData: [],
        loading: false,
        per_page:10,
        current_page:1,
        last_page:0,
        total:0,
        pageTags:{},
        filterForm:{
          course_category_id:null,
          teacher_id:null,
          keyword:null,
          timeRange:[]
        },
        bigCategoryData:[],
        teachersData:[],
        totalData:{}
      };
    },
    methods: {
      getList() {
        self.loading = true;
        self.$fetch("admin/statistic/courses",{
          course_category_id:self.filterForm.course_category_id,
          teacher_id:self.filterForm.teacher_id,
          start_date:self.filterForm.timeRange?self.filterForm.timeRange[0]:null,
          end_date:self.filterForm.timeRange?self.filterForm.timeRange[1]:null,
          keyword:self.filterForm.keyword,
          page:self.current_page,
          per_page:self.per_page
        }).then(response => {
          if (response.status == 200) {
            self.tableData = response.data.data;
            self.total = response.data.meta.total;
            self.current_page = response.data.meta.current_page;
            self.last_page = response.data.meta.last_page;
          } else{
            console.log(response.message);
          }

          self.loading = false;
          if(JSON.parse(sessionStorage.getItem("curTags"))){
            self.pageTags = JSON.parse(sessionStorage.getItem("curTags"));
          }
        }).catch(err => {
          console.log('××××××××××××××');
          console.log(err);
          self.loading = false;
        });
      },
      handleSizeChange(val) {
        self.per_page = val;
        self.getList();
      },
      handleCurrentChange(val) {
        self.current_page = val;
        self.getList();
      },
      dateTimeChage(){
        self.toSearch();
      },
      toSearch(){
        self.current_page = 1;
        self.getList();
        self.getTotalData();
        self.getChartData();
      },
      toClear(){
        self.filterForm = {
          course_category_id:null,
          teacher_id:null,
          keyword:null,
          timeRange:[]
        }
        self.toSearch();
      },
      getBigCategory(){
        self.$fetch("admin/course/category",{
          course_category_id:0
        }).then(response => {
          if (response.status == 200) {
           self.bigCategoryData = response.data.data;
          } else{
            console.log(response.message);
          }
        }).catch(err => {
          console.log('××××××××××××××');
          console.log(err);
        });
      },
      getTeachersList(){
        self.$fetch("admin/teacher").then(response => {
          if (response.status == 200) {
           self.teachersData = response.data.data;
          } else{
            console.log(response.message);
          }
        }).catch(err => {
          console.log('××××××××××××××');
          console.log(err);
        });
      },
      getTotalData(){
        self.$fetch("admin/statistic/courses",{
          type:1,
          course_category_id:self.filterForm.course_category_id,
          teacher_id:self.filterForm.teacher_id,
          start_date:self.filterForm.timeRange?self.filterForm.timeRange[0]:null,
          end_date:self.filterForm.timeRange?self.filterForm.timeRange[1]:null,
          keyword:self.filterForm.keyword
        }).then(response => {
          if (response.status == 200) {
            self.totalData = response.data.data;
          } else{
            console.log(response.message);
          }
        }).catch(err => {
          console.log('××××××××××××××');
          console.log(err);
        });
      },
      getChartData(){
        self.$fetch("admin/statistic",{
          start_date:self.filterForm.timeRange?self.filterForm.timeRange[0]:null,
          end_date:self.filterForm.timeRange?self.filterForm.timeRange[1]:null,
        }).then(response => {
          if (response.status == 200) {
            self.withdrawals = response.data.data.withdrawals;
            self.incomes = response.data.data.incomes;
            self.initChart();
          } else{
            console.log(response.message);
          }
        }).catch(err => {
          console.log('××××××××××××××');
          console.log(err);
        });
      },
      indexMethod(index) {
        return index + ((self.current_page-1) * self.per_page) + 1;
      },
      initChart(){
        var myChart = echarts.init(document.getElementById('chartbox'));
        var option = {
          // title: {
          //   text: 'نەقلەشتۈرۈش ئەھۋالى',
          //   x: 'center',
          //   textStyle:{
          //     fontFamily:'UKIJTor'
          //   }
          // },
          legend: {
            orient: 'vertical',
            right: 0,
            data: ['نەقلەشتۈرمىگەن سومما', 'نەقلەشتۈرگەن سومما'],
            textStyle:{
              fontFamily:'UKIJTor'
            }
          },
          tooltip: {
            trigger: 'item',
            formatter: "{a} <br/>{b} : {c}",
            textStyle:{
              fontFamily:'UKIJTor'
            }
          },
          color: ['#E6A23C', '#67C23A'],
          stillShowZeroSum: false,
          series: [
            {
              name: 'نەقلەشتۈرۈش ئەھۋالى',
              type: 'pie',
              radius: '60%',
              label:{
                fontFamily:'UKIJTor',
                align:'right',
                alignTo:'labelLine'
              },
              data: [
                {value: self.incomes, name: 'نەقلەشتۈرمىگەن سومما'},
                {value: self.withdrawals, name: 'نەقلەشتۈرگەن سومما'}
              ],
              itemStyle: {
                emphasis: {
                  shadowBlur: 10,
                  shadowOffsetX: 0,
                  shadowColor: 'rgba(128, 128, 128, 0.5)'
                }
              }
            }
          ]
        };
        myChart.setOption(option);
      },
      sortChange(e){
        console.log(e.prop);
        console.log(e.order);
      }
    }
  };
</script>

<style lang="scss" scoped>
  .search-box{
    .el-select,.el-input,.el-date-editor{
      width: 100%;
    }
    [class*="el-col-"]{
      float: right;
    }
  }
  .total-box{
    p{
      display: flex;
      display: -webkit-flex;
      flex-direction: row;
      align-items: center;
      margin-bottom: 23px;
      padding-right: 10%;
      label{
        font-size: 18px;
        width: 300px;
        &:after{
          content: ':';
        }
      }
      span{
        font-size: 20px;
      }
    }
  }
</style>

